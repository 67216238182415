import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label, Modal, ModalBody,
  ModalFooter, ModalHeader, Row
} from 'reactstrap'
import FalconCardHeader from '../common/FalconCardHeader'
import Loader from '../common/Loader'

const CustomerForm = () => {
  const history = useHistory()

  const { uuid } = useParams()
  const [loading, setLoading] = useState(true)
  const [customer, setCustomer] = useState({})
  const [modal, setModal] = useState(false)
  const [modalErrors, setModalErrors] = useState(false)
  const [errors, setErrors] = useState("")

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/customers/' + uuid).then(response => {
      console.log(response)
      setCustomer(response.data)
      setLoading(false)
    }).catch((response) => {
      console.log(response)
    })
  }, [uuid])

  const { handleSubmit } = useForm({ reValidateMode: 'onSubmit' })
  const onSubmit = () => {
    axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/customers/' + uuid, {
      user_id: customer.user_id,
      name: customer.user?.name,
      kana: customer.kana,
      phone: customer.phone,
      email: customer.user?.email,
      customer_status_id: customer.customer_status_id,
      payment_method_id: customer.payment_method_id,
      postcode: customer.postcode,
      address: customer.address,
      building: customer.building,
      gmo_id: customer.gmo_id,
      bank_no: customer.bank_no,
      bank_name: customer.bank_name,
      bank_branch_no: customer.bank_branch_no,
      bank_branch_name: customer.bank_branch_name,
      bank_account_no: customer.bank_account_no,
      bank_deposit_type: customer.bank_deposit_type,
      bank_deposit_name: customer.bank_deposit_name,
    }).then(response => {
      console.log(response)
      setCustomer(response.data)
      setModal(true)
    }).catch((error) => {
      console.log(error.response)
      console.log(error.response.data.errors)
      setErrors(error.response.data.errors.email[0])
      setModalErrors(true)
    })
  }

  return (
    <>
      <Card className="mb-3">
        <FalconCardHeader title="お客様情報" />
        <CardBody className="bg-light border-top">
          {loading ? (
            <Loader />
          ) : (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className="mb-3">
                <Col lg className="col-xxl-5">
                  <FormGroup>
                    <Label>お客様番号</Label>
                    <Input type="text" id="uuid" value={uuid} readOnly />
                  </FormGroup>
                  <FormGroup>
                    <Label for="name">お客様名</Label>
                    <Input type="text" id="name" value={customer.user?.name} onChange={({ target }) => setCustomer({ ...customer, user: { ...customer.user, name: target.value } })} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="kana">フリガナ</Label>
                    <Input type="text" id="kana" value={customer.kana} onChange={({ target }) => setCustomer({ ...customer, kana: target.value })} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="phone">電話</Label>
                    <Input type="text" id="phone" value={customer.phone} onChange={({ target }) => setCustomer({ ...customer, phone: target.value })} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input type="text" id="email" value={customer.user?.email} onChange={({ target }) => setCustomer({ ...customer, user: { ...customer.user, email: target.value } })} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="customer_status_id">お客様ステータス</Label>
                    <Input type="select" id="customer_status_id" value={customer.customer_status_id} onChange={({ target }) => setCustomer({ ...customer, customer_status_id: target.value })}>
                      <option value="1">ご契約中</option>
                      <option value="2">ご契約終了</option>
                      <option value="3">解約済</option>
                      <option value="4">取消</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="postcode">郵便番号</Label>
                    <Input type="text" id="postcode" value={customer.postcode} onChange={({ target }) => setCustomer({ ...customer, postcode: target.value })} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="payment_method_id">決済手段</Label>
                    <Input type="select" id="payment_method_id" value={customer.payment_method_id} onChange={({ target }) => setCustomer({ ...customer, payment_method_id: target.value })}>
                      <option value="1">クレジットカード</option>
                      <option value="2">口座振替</option>
                      <option value="3">銀行振込</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
                  <FormGroup>
                    <Label for="postcode">郵便番号</Label>
                    <Input type="text" id="postcode" value={customer.postcode} onChange={({ target }) => setCustomer({ ...customer, postcode: target.value })} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="address">住所</Label>
                    <Input type="text" id="address" value={customer.address} onChange={({ target }) => setCustomer({ ...customer, address: target.value })} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="building">建物・マンション名</Label>
                    <Input type="text" id="building" value={customer.building} onChange={({ target }) => setCustomer({ ...customer, building: target.value })} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="gmo_id">GMO PAYMENT会員ID</Label>
                    <Input type="text" id="gmo_id" value={customer.gmo_id} onChange={({ target }) => setCustomer({ ...customer, gmo_id: target.value })} />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg className="col-xxl-5">
                <FormGroup>
                    <Label for="bank_no">引落銀行番号</Label>
                    <Input type="text" id="bank_no" value={customer.bank_no} onChange={({ target }) => setCustomer({ ...customer, bank_no: target.value })} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="bank_branch_no">引落支店番号</Label>
                    <Input type="text" id="bank_branch_no" value={customer.bank_branch_no} onChange={({ target }) => setCustomer({ ...customer, bank_branch_no: target.value })} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="bank_deposit_type">預金種目</Label>
                    <Input type="text" id="bank_deposit_type" value={customer.bank_deposit_type} onChange={({ target }) => setCustomer({ ...customer, bank_deposit_type: target.value })} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="bank_deposit_name">預金者名</Label>
                    <Input type="text" id="bank_deposit_name" value={customer.bank_deposit_name} onChange={({ target }) => setCustomer({ ...customer, bank_deposit_name: target.value })} />
                  </FormGroup>
                </Col>
                <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
                <FormGroup>
                    <Label for="bank_name">引落銀行名</Label>
                    <Input type="text" id="bank_name" value={customer.bank_name} onChange={({ target }) => setCustomer({ ...customer, bank_name: target.value })} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="bank_branch_name">引落支店名</Label>
                    <Input type="text" id="bank_branch_name" value={customer.bank_branch_name} onChange={({ target }) => setCustomer({ ...customer, bank_branch_name: target.value })} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="bank_account_no">口座番号</Label>
                    <Input type="text" id="bank_account_no" value={customer.bank_account_no} onChange={({ target }) => setCustomer({ ...customer, bank_account_no: target.value })} />
                  </FormGroup>
                </Col>
              </Row>
              <Col className="d-flex justify-content-between">
                <Button color="secondary" onClick={() => history.goBack()}>
                  戻る
                </Button>
                <Button color="primary">保存</Button>
              </Col>
              <Modal isOpen={modal}>
                <ModalHeader>保存完了</ModalHeader>
                <ModalBody>
                  保存しました
                </ModalBody>
                <ModalFooter>
                  <Button onClick={() => setModal(false)}>
                    閉じる
                  </Button>
                </ModalFooter>
              </Modal>
              <Modal isOpen={modalErrors}>
                <ModalHeader>入力エラー</ModalHeader>
                <ModalBody>
                  {errors}
                </ModalBody>
                <ModalFooter>
                  <Button onClick={() => setModalErrors(false)}>
                    閉じる
                  </Button>
                </ModalFooter>
              </Modal>
            </Form>
          )}
        </CardBody>
      </Card>
    </>
  )
}

export default CustomerForm
