import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  Button, Card,
  CardBody,
  CardFooter, CustomInput, FormGroup, Input, Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import FalconCardHeader from "../common/FalconCardHeader";
import Flex from '../common/Flex';
import QuillEditor from '../common/QuillEditor';
import Select from 'react-select';

const Notice = () => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [content, setContent] = useState('');
  const [subject, setSubject] = useState('');
  const [status1, setStatus1] = useState(false);
  const [status2, setStatus2] = useState(false);
  const [status3, setStatus3] = useState(false);
  const [status4, setStatus4] = useState(false);
  const [options, setOptions] = useState([]);
  const [months, setMonths] = useState([]);

  const handleClear = () => {
    setContent('');
    setSubject('');
    setStatus1(false);
    setStatus2(false);
    setStatus3(false);
    setStatus4(false);
    setMonths([]);
  };

  const handleSubmit = () => {
    let statuses = []
    if (status1) statuses.push(1)
    if (status2) statuses.push(2)
    if (status3) statuses.push(3)
    if (status4) statuses.push(4)
    axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/notice', {
      subject: subject,
      body: content,
      statuses: statuses,
      months: months.map((item) => {
        return item.value
      }),
    }).then(response => {
      console.log(response)
      setCompleteModal(true)
    }).catch((response) => {
      console.log(response)
    })
    setConfirmModal(false)
    handleClear();
  };

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/notice/months',).then(response => {
      console.log(response)
      setOptions(response.data)
    }).catch((response) => {
      console.log(response)
    })
  }, []);

  const [confirmModal, setConfirmModal] = useState(false)
  const [completeModal, setCompleteModal] = useState(false)

  useEffect(() => {
    setIsDisabled(!content || !subject || (!status1 && !status2 && !status3 && !status4));
  }, [subject, content, status1, status2, status3, status4]);

  return (
    <>
      <Card className="mt-3">
        <FalconCardHeader title="一括メール配信" light={false} />
        <CardBody className="border-top">
          <Input
            className="form-control border-0 outline-none mb-2"
            type="text"
            placeholder="件名"
            value={subject}
            onChange={({ target }) => setSubject(target.value)}
            required
          />
          <FormGroup>
            <CustomInput
              type="checkbox"
              id="status1"
              label="ご契約中"
              className="ml-3"
              inline
              checked={status1}
              onChange={({ target }) => setStatus1(target.checked)}
            />
            <CustomInput
              type="checkbox"
              id="status2"
              label="ご契約終了"
              inline
              checked={status2}
              onChange={({ target }) => setStatus2(target.checked)}
            />
            <CustomInput
              type="checkbox"
              id="status3"
              label="解約済"
              inline
              checked={status3}
              onChange={({ target }) => setStatus3(target.checked)}
            />
            <CustomInput
              type="checkbox"
              id="status4"
              label="取消"
              inline
              checked={status4}
              onChange={({ target }) => setStatus4(target.checked)}
            />
          </FormGroup>
          <Select
            value={months}
            onChange={value => setMonths(value)}
            classNamePrefix='react-select'
            options={options.map(function (item) {
              return { value: item.month, label: item.month + '請求対象者' }
            })}
            isMulti
            placeholder="特定月の請求対象者にだけ送信"
          />
          <QuillEditor value={content} onChange={setContent} style={{ height: 300, marginBottom: 44 }} />
        </CardBody>
        <CardFooter tag={Flex} justify="between" className="border-top border-200">
          <Flex align="center">
            <Button color="primary" size="sm" className="px-5 mr-2" disabled={isDisabled} type="submit" onClick={() => setConfirmModal(true)}>
              送信
            </Button>
          </Flex>
        </CardFooter>
      </Card>
      <Modal isOpen={confirmModal}>
        <ModalHeader>送信確認</ModalHeader>
        <ModalBody>
          送信しますか？
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setConfirmModal(false)}>
            キャンセル
          </Button>
          <Button color="primary" onClick={() => handleSubmit()}>送信</Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={completeModal}>
        <ModalHeader>送信完了</ModalHeader>
        <ModalBody>
          送信しました
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setCompleteModal(false)}>
            閉じる
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Notice;
