import axios from 'axios'
import React, { useState } from 'react';
import { Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import FormGroup from "reactstrap/es/FormGroup";
import FalconCardHeader from '../common/FalconCardHeader';
import Loader from '../common/Loader';

const Invoices = () => {
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  const createInvoice = () => {
    setLoading(true)
    axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/invoices/create')
      .then(response => {
        console.log(response)
        setLoading(false)
        setModal(true)
      }).catch((response) => {
        console.log(response)
      })
  }

  const approveInvoice = () => {
    setLoading(true)
    axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/invoices/approve')
      .then(response => {
        console.log(response)
        setLoading(false)
        setModal(true)
      }).catch((response) => {
        console.log(response)
      })
  }

  return (
    <>
      <Card className="mt-3 mb-3">
        <FalconCardHeader title="請求書PDF作成" light={false} />
        <CardBody className="bg-light border-top">
          <FormGroup className="form-group mt-3 ">
            {loading ? (
              <Loader />
            ) : (
              <Button color="primary" onClick={createInvoice}>請求書PDFの作成処理を開始</Button>
            )}
          </FormGroup>
        </CardBody>
      </Card>
      <Card className="mt-3 mb-3">
        <FalconCardHeader title="需要家向け公開" light={false} />
        <CardBody className="bg-light border-top">
          <FormGroup className="form-group mt-3 ">
            {loading ? (
              <Loader />
            ) : (
              <Button color="primary" onClick={approveInvoice}>需要家向け画面での公開を開始</Button>
            )}
          </FormGroup>
        </CardBody>
      </Card>
      <Modal isOpen={modal}>
        <ModalHeader>処理実行完了</ModalHeader>
        <ModalBody>
          処理実行が完了しました
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setModal(false)}>
            閉じる
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default Invoices;
