import axios from 'axios'
import { ja } from 'moment/locale/ja'
import React, { useEffect, useState } from 'react'
import Datetime from 'react-datetime'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label, Modal, ModalBody,
  ModalFooter, ModalHeader, Row
} from 'reactstrap'
import FalconCardHeader from '../common/FalconCardHeader'
import Loader from '../common/Loader'

const ContractForm = () => {
  const history = useHistory()

  const { uuid } = useParams()
  const [loading, setLoading] = useState(true)
  const [contract, setContract] = useState({})
  const [modal, setModal] = useState(false)
  const [modalErrors, setModalErrors] = useState(false)
  const [errors, setErrors] = useState("")

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/contracts/' + uuid).then(response => {
      console.log(response)
      setContract(response.data)
      setLoading(false)
    }).catch((response) => {
      console.log(response)
    })
  }, [uuid])

  const { handleSubmit } = useForm({ reValidateMode: 'onSubmit' })
  const onSubmit = () => {
    if (contract.end && contract.end < contract.start) {
      setErrors("ご契約終了日はご利用開始日より前の日付に出来ません")
      setModalErrors(true)
      return
    }
    axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/contracts/' + uuid, {
      name: contract.name,
      kana: contract.kana,
      menu: contract.menu,
      start: contract.start,
      end: contract.end,
      address: contract.address,
      area_id: contract.area_id,
      contract_type_id: contract.contract_type_id,
      contract_style_id: contract.contract_style_id,
      contract_status_id: contract.contract_status_id,
      capacity: contract.capacity,
      contract_unit_id: contract.contract_unit_id,
      // payment_method_id: contract.payment_method_id,
      management_price: contract.management_price,
    }).then(response => {
      console.log(response)
      setContract(response.data)
      setModal(true)
    }).catch((response) => {
      console.log(response)
    })
  }

  return (
    <>
      <Card className="mb-3">
        <FalconCardHeader title="ご契約情報" light={false} />
        <CardBody className="bg-light border-top">
          {loading ? (
            <Loader />
          ) : (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row className="mb-3">
                <Col lg className="col-xxl-5">
                  <FormGroup>
                    <Label for="name">施設名</Label>
                    <Input type="text" id="name" value={contract.name} onChange={({ target }) => setContract({ ...contract, name: target.value })} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="kana">フリガナ</Label>
                    <Input type="text" id="kana" value={contract.kana} onChange={({ target }) => setContract({ ...contract, kana: target.value })} />
                  </FormGroup>
                  <FormGroup>
                    <Label>供給地点特定番号</Label>
                    <Input type="text" value={uuid} readOnly />
                  </FormGroup>
                  <FormGroup>
                    <Label for="menu">ご契約メニュー</Label>
                    <Input type="text" id="menu" value={contract.menu} onChange={({ target }) => setContract({ ...contract, menu: target.value })} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="start">ご利用開始日</Label>
                    <Datetime
                      renderInput={(props) => {
                        return (
                          <Input id="start" {...props} value={contract.start} onChange={({ target }) => setContract({ ...contract, start: target.value })} autoComplete="off" />
                        )
                      }}
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      closeOnSelect={true}
                      className="mb-3"
                      locale={ja}
                      value={contract.start}
                      onChange={(value) => setContract({ ...contract, start: value.format('YYYY-MM-DD') })}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="end">ご契約終了日</Label>
                    <Datetime
                      renderInput={(props) => {
                        return (
                          <Input id="end" {...props} value={contract.end ?? ""} onChange={({ target }) => setContract({ ...contract, end: target.value })} autoComplete="off" />
                        )
                      }}
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      closeOnSelect={true}
                      className="mb-3"
                      locale={ja}
                      value={contract.end}
                      onChange={(value) => setContract({ ...contract, end: value.format('YYYY-MM-DD') })}
                    />
                  </FormGroup>
                  {/* <FormGroup>
                    <Label for="payment_method">決済手段</Label>
                    <Input type="select" id="payment_method" value={contract.payment_method} onChange={({ target }) => setContract({ ...contract, payment_method: target.value })}>
                      <option value="1">クレジットカード</option>
                      <option value="2">口座振替</option>
                      <option value="3">銀行振込</option>
                    </Input>
                  </FormGroup> */}
                </Col>
                <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
                  <FormGroup>
                    <Label for="address">需要場所</Label>
                    <Input type="text" id="address" value={contract.address} onChange={({ target }) => setContract({ ...contract, address: target.value })} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="area_id">エリア</Label>
                    <Input type="select" id="area_id" value={contract.area_id} onChange={({ target }) => setContract({ ...contract, area_id: target.value })}>
                      <option value="3">03東京</option>
                      <option value="4">04中部</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="contract_type_id">ご契約種別</Label>
                    <Input type="select" id="contract_type_id" value={contract.contract_type_id} onChange={({ target }) => setContract({ ...contract, contract_type_id: target.value })}>
                      <option value="0">未設定</option>
                      <option value="1">アンペアブレーカー</option>
                      <option value="2">主開閉器</option>
                      <option value="3">実量制</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="contract_style_id">ご契約形態</Label>
                    <Input type="select" id="contract_style_id" value={contract.contract_style_id} onChange={({ target }) => setContract({ ...contract, contract_style_id: target.value })}>
                      <option value="1">電灯</option>
                      <option value="2">動力</option>
                      <option value="3">高圧</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="contract_status_id">ご契約ステータス</Label>
                    <Input type="select" id="contract_status_id" value={contract.contract_status_id} onChange={({ target }) => setContract({ ...contract, contract_status_id: target.value })}>
                      <option value="1">ご契約中</option>
                      <option value="2">ご契約終了</option>
                      <option value="3">解約済</option>
                      <option value="4">取消</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="capacity">ご契約容量値</Label>
                    <Input type="text" id="capacity" value={contract.capacity} onChange={({ target }) => setContract({ ...contract, capacity: target.value })} />
                  </FormGroup>
                  <FormGroup>
                    <Label for="contract_unit_id">ご契約容量単位</Label>
                    <Input type="select" id="contract_unit_id" value={contract.contract_unit_id} onChange={({ target }) => setContract({ ...contract, contract_unit_id: target.value })}>
                      <option value="0">未設定</option>
                      <option value="1">A</option>
                      <option value="2">kW</option>
                      <option value="3">kVA</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="management_price">事業費</Label>
                    <Input type="text" id="management_price" value={contract.management_price} onChange={({ target }) => setContract({ ...contract, management_price: target.value })} />
                  </FormGroup>
                </Col>
              </Row>
              <Col className="d-flex justify-content-between">
                <Button color="secondary" onClick={() => history.goBack()}>
                  戻る
                </Button>
                <Button color="primary">保存</Button>
              </Col>
              <Modal isOpen={modal}>
                <ModalHeader>保存完了</ModalHeader>
                <ModalBody>
                  保存しました
                </ModalBody>
                <ModalFooter>
                  <Button onClick={() => setModal(false)}>
                    閉じる
                  </Button>
                </ModalFooter>
              </Modal>
              <Modal isOpen={modalErrors}>
                <ModalHeader>入力エラー</ModalHeader>
                <ModalBody>
                  {errors}
                </ModalBody>
                <ModalFooter>
                  <Button onClick={() => setModalErrors(false)}>
                    閉じる
                  </Button>
                </ModalFooter>
              </Modal>
            </Form>
          )}
        </CardBody>
      </Card>
    </>
  )
}

export default ContractForm
