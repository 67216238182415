import axios from 'axios';
import Encoding from 'encoding-japanese';
import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import FormGroup from "reactstrap/es/FormGroup";
import Label from "reactstrap/es/Label";
import FalconCardHeader from '../common/FalconCardHeader';
import Select from "../common/Select";

const Exports = () => {
  const [isPaymentsDisabled, setIsPaymentsDisabled] = useState(false);
  const [isWashoutDisabled, setIsWashoutDisabled] = useState(false);
  const [isBillingDisabled, setIsBillingDisabled] = useState(false);
  const [options, setOptions] = useState([]);
  const [washoutYm, setWashoutYm] = useState([]);
  const [billingYm, setBillingYm] = useState([]);
  const [invoiceYm, setInvoiceYm] = useState([]);
  const [paymentsYm, setPaymentsYm] = useState([]);

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/export/months',).then(response => {
      console.log(response)
      setOptions(response.data)
    }).catch((response) => {
      console.log(response)
    })
    setIsPaymentsDisabled(!paymentsYm);
    setIsWashoutDisabled(!washoutYm);
    setIsBillingDisabled(!billingYm);
  }, []);

  const downloadCsv = (type, reading) => {
    axios({
      url: `${process.env.REACT_APP_API_ENDPOINT}/api/export/${type}?month=${reading.value}`,
      method: 'GET'
    }
    ).then(response => {
      console.log(response)
      // https://github.com/polygonplanet/encoding.js/blob/master/README_ja.md
      const unicodeArray = Encoding.stringToCode(response.data)
      const sjisArray = Encoding.convert(unicodeArray, {
        to: 'SJIS',
        from: 'UNICODE'
      })
      const u8a = new Uint8Array(sjisArray);
      const href = URL.createObjectURL(new Blob([u8a], { type: 'text/csv' }));
      const link = document.createElement('a');
      link.href = href;
      switch (type) {
        case 'payments':
          link.setAttribute('download', '請求データ.csv');
          break;
        case 'washout':
          link.setAttribute('download', '洗替データ.csv');
          break;
        case 'billing':
          link.setAttribute('download', '一括処理ファイル.csv');
          break;
        case 'invoice':
            link.setAttribute('download', '口座振替データ.csv');
            break;
        }
      document.body.appendChild(link);
      link.click();
    }).catch((error) => {
      console.log(error);
    });
  }

  return (
    <>
      <Card className="mt-3 mb-3">
        <FalconCardHeader title="洗替データエクスポート" light={false} />
        <CardBody className="bg-light border-top">
          <FormGroup className="form-group mb-4">
            <Label className="mt-1">
              エクスポート対象年月
            </Label>
            <Select
              classNamePrefix='react-select'
              options={options.map(function (item) {
                return { value: item.month, label: item.month }
              })}
              placeholder="対象年月を選択してください"
              styles={{
                menu: provided => ({ ...provided, zIndex: 9999 })
              }}
              value={washoutYm}
              onChange={value => setWashoutYm(value)}
              className="border-1"
            />
          </FormGroup>
          <FormGroup className="form-group">
            <a href="javascript:void(0)" onClick={() => downloadCsv('washout', washoutYm)} className="mt-3 btn btn-primary">エクスポート</a>
          </FormGroup>
        </CardBody>
      </Card>

      <Card className="mt-3 mb-3">
        <FalconCardHeader title="一括処理ファイルエクスポート" light={false} />
        <CardBody className="bg-light border-top">
          <FormGroup className="form-group mb-4">
            <Label className="mt-1">
              エクスポート対象年月
            </Label>
            <Select
              classNamePrefix='react-select'
              options={options.map(function (item) {
                return { value: item.month, label: item.month }
              })}
              placeholder="対象年月を選択してください"
              styles={{
                menu: provided => ({ ...provided, zIndex: 9999 })
              }}
              value={billingYm}
              onChange={value => setBillingYm(value)}
              className="border-1"
            />
          </FormGroup>
          <FormGroup className="form-group">
            <a href="javascript:void(0)" onClick={() => downloadCsv('billing', billingYm)} className="mt-3 btn btn-primary">エクスポート</a>
          </FormGroup>
        </CardBody>
      </Card>

      <Card className="mt-3 mb-3">
        <FalconCardHeader title="口座振替データエクスポート" light={false} />
        <CardBody className="bg-light border-top">
          <FormGroup className="form-group mb-4">
            <Label className="mt-1">
              エクスポート対象年月
            </Label>
            <Select
              classNamePrefix='react-select'
              options={options.map(function (item) {
                return { value: item.month, label: item.month }
              })}
              placeholder="対象年月を選択してください"
              styles={{
                menu: provided => ({ ...provided, zIndex: 9999 })
              }}
              value={invoiceYm}
              onChange={value => setInvoiceYm(value)}
              className="border-1"
            />
          </FormGroup>
          <FormGroup className="form-group">
            <a href="javascript:void(0)" onClick={() => downloadCsv('invoice', invoiceYm)} className="mt-3 btn btn-primary">エクスポート</a>
          </FormGroup>
        </CardBody>
      </Card>

      <Card className="mt-3 mb-3">
        <FalconCardHeader title="請求データエクスポート" light={false} />
        <CardBody className="bg-light border-top">
          <FormGroup className="form-group mb-4">
            <Label className="mt-1">
              エクスポート対象年月
            </Label>
            <Select
              classNamePrefix='react-select'
              options={options.map(function (item) {
                return { value: item.month, label: item.month }
              })}
              placeholder="対象年月を選択してください"
              styles={{
                menu: provided => ({ ...provided, zIndex: 9999 })
              }}
              value={paymentsYm}
              onChange={value => setPaymentsYm(value)}
              className="border-1"
            />
          </FormGroup>
          <FormGroup className="form-group">
            <a href="javascript:void(0)" onClick={() => downloadCsv('payments', paymentsYm)} className="mt-3 btn btn-primary">エクスポート</a>
          </FormGroup>
        </CardBody>
      </Card>
    </>
  )
}

export default Exports;
