import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ContractForm from '../components/dashboard/ContractForm';
import Contracts from '../components/dashboard/Contracts';
import CustomerDetail from '../components/dashboard/CustomerDetail';
import CustomerForm from '../components/dashboard/CustomerForm';
import Customers from '../components/dashboard/Customers';
import Exports from '../components/dashboard/Export';
import Uploads from '../components/dashboard/Uploads';
import Invoices from '../components/dashboard/Invoices';
import Notice from '../components/dashboard/Notice';
import RenewablePrices from '../components/dashboard/RenewablePrices';

const DashboardRoutes = () => (
  <Switch>
    <Route path="/" exact component={Customers} />
    <Route path="/customers" exact component={Customers} />
    <Route path="/customer-detail/:uuid" exact component={CustomerDetail} />
    <Route path="/customer-form/:uuid" exact component={CustomerForm} />
    <Route path="/contracts" exact component={Contracts} />
    <Route path="/contract-form/:uuid" exact component={ContractForm} />
    <Route path="/renewable_prices" exact component={RenewablePrices} />
    <Route path="/exports" exact component={Exports} />
    <Route path="/uploads" exact component={Uploads} />
    <Route path="/invoices" exact component={Invoices} />
    <Route path="/notice" component={Notice} />
    <Redirect to="/errors/404" />
  </Switch>
);

export default DashboardRoutes;
