import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';
import Footer from '../components/footer/Footer';
import NavbarTop from '../components/navbar/NavbarTop';
import withRedirect from '../hoc/withRedirect';
import DashboardRoutes from '../routes/DashboardRoutes';

const DashboardLayout = ({ setRedirect, setRedirectUrl }) => {
  const [isLogin, setIsLogin] = useState(false);
  useEffect(() => {
    axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/admin')
      .then((response) => {
        console.log(response);
        setIsLogin(true)
      })
      .catch((response) => {
        console.log(response);
        setRedirectUrl("/auth/login");
        setRedirect(true);
      });
  }, [setRedirect, setRedirectUrl]);

  return (
    <>
      {isLogin && (
        <div className="container">
          <div className="content">
            <NavbarTop />
            <Switch>
              <DashboardRoutes />
            </Switch>
            <Footer />
          </div>
        </div>
      )}
    </>
  )
}

export default withRedirect(DashboardLayout);
