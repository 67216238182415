import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Card, CardBody, Form, FormGroup, Label, Media, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import cloudUpload from "../../assets/img/icons/cloud-upload.svg";
import FalconCardHeader from '../common/FalconCardHeader';
import FalconDropzone from "../common/FalconDropzone";

const Upload = () => {
  const [modal, setModal] = useState(false)
  const { handleSubmit } = useForm({ reValidateMode: 'onSubmit' })
  const onSubmitCustomers = (files) => {
    console.log(files);
    axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/upload/customers', {
      customers: files[0]
    }).then(response => {
      console.log(response)
      setModal(true)
    }).catch((response) => {
      console.log(response)
    })
  }
  const onSubmitContracts = (files) => {
    axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/upload/contracts', {
      contracts: files[0]
    }).then(response => {
      console.log(response)
      setModal(true)
    }).catch((response) => {
      console.log(response)
    })
  }
  return (
    <>
      <Card className="mt-3 mb-3">
        <FalconCardHeader title="お客様情報アップロード" light={false} />
        <CardBody className="bg-light border-top">
          <Form onSubmit={handleSubmit(onSubmitCustomers)}>
            <FormGroup>
              <Label>アップロードファイル</Label>
              <FalconDropzone
                multiple={false}
                accept=".csv"
                onChange={files => {
                  onSubmitCustomers(files);
                }}
                placeholder={
                  <>
                    <Media className=" fs-0 mx-auto d-inline-flex align-items-center">
                      <img src={cloudUpload} alt="" width={25} className="mr-2" />
                    </Media>
                    <p className="mb-0 w-75 mx-auto text-500">
                    Upload with csv file
                    </p>
                  </>
                }
              />
            </FormGroup>
            {/* <Button color="primary">保存</Button> */}
          </Form>
        </CardBody>
      </Card>
      <Card className="mb-3">
        <FalconCardHeader title="ご契約情報アップロード" light={false} />
        <CardBody className="bg-light border-top">
          <Form onSubmit={handleSubmit(onSubmitContracts)}>
            <FormGroup>
              <Label for="exampleFile">アップロードファイル</Label>
              <FalconDropzone
                multiple={false}
                accept=".csv"
                onChange={files => {
                  onSubmitContracts(files);
                }}
                placeholder={
                  <>
                    <Media className=" fs-0 mx-auto d-inline-flex align-items-center">
                      <img src={cloudUpload} alt="" width={25} className="mr-2" />
                    </Media>
                    <p className="mb-0 w-75 mx-auto text-500">
                      Upload with csv file
                    </p>
                  </>
                }
              />
            </FormGroup>
            {/* <Button color="primary">保存</Button> */}
          </Form>
        </CardBody>
      </Card>
      <Modal isOpen={modal}>
        <ModalHeader>保存完了</ModalHeader>
        <ModalBody>
          保存しました
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setModal(false)}>
            閉じる
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default Upload;
