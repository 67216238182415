import axios from 'axios';
import React, { createRef, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import Loader from '../common/Loader';

const RenewablePrices = () => {
  const [loading, setLoading] = useState(true);
  const [renewablePrice, setRenewablePrice] = useState({})
  const [registerModal, setRegisterModal] = useState(false)
  const [deleteId, setDeleteId] = useState()
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false)
  const [renewablePrices, setRenewablePrices] = useState([]);

  const RenewablePriceTotal = ({ sizePerPage, totalSize, page, lastIndex }) => (
    <span>
      {(page - 1) * sizePerPage + 1} to {lastIndex > totalSize ? totalSize : lastIndex} of {totalSize} —{' '}
    </span>
  );

  const register = () => {
    axios.post(process.env.REACT_APP_API_ENDPOINT + '/api/renewable_prices', {
      start: renewablePrice.start,
      price: renewablePrice.price,
    }).then(response => {
      console.log(response)
      setRenewablePrices(response.data)
      setRegisterModal(false)
    }).catch((response) => {
      console.log(response)
    });
  }

  const deleteConfirm = (id) => {
    setDeleteId(id)
    setDeleteConfirmModal(true)
  }

  const deleteRenewablePrice = () => {
    axios.delete(process.env.REACT_APP_API_ENDPOINT + '/api/renewable_prices/' + deleteId).then(response => {
      console.log(response)
      setRenewablePrices(response.data);
      setDeleteConfirmModal(false)
    }).catch((response) => {
      console.log(response)
    })
  }

  const columns = [
    {
      dataField: 'start',
      text: '適用開始日',
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
    },
    {
      dataField: 'price',
      text: '単価',
      classes: 'border-0 align-middle',
      headerClasses: 'border-0',
    },
    {
      dataField: "id",
      text: "削除",
      formatter: (_, row) => {
          return <Button className="ml-3" color="falcon-default" size="sm" onClick={() => deleteConfirm(row.id)}>削除</Button>
      },
    },
  ];

  let table = createRef();
  const handleNextPage = ({ page, onPageChange }) => () => {
    onPageChange(page + 1);
  };

  const handlePrevPage = ({ page, onPageChange }) => () => {
    onPageChange(page - 1);
  };

  const options = {
    custom: true,
    sizePerPage: 20,
    totalSize: renewablePrices.length
  };

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_ENDPOINT + '/api/renewable_prices').then(response => {
      console.log(response);
      setRenewablePrices(response.data);
      setLoading(false);
    }).catch((response) => {
      console.log(response);
    });
  }, []);

  return (
    <>
      <Card className="mt-3 mb-3">
        <FalconCardHeader title="再エネ賦課金単価一覧" light={false}>
          <Form inline>
            <Button className="ml-3" color="falcon-default" size="sm" onClick={() => setRegisterModal(true)}>
              新規登録
            </Button>
          </Form>
        </FalconCardHeader>
        <CardBody className="p-0 border-top">
          {loading ? (
            <Loader />
          ) : (
            <PaginationProvider pagination={paginationFactory(options)}>
              {({ paginationProps, paginationTableProps }) => {
                const lastIndex = paginationProps.page * paginationProps.sizePerPage;
                return (
                  <>
                    <div className="table-responsive">
                      <BootstrapTable
                        ref={table}
                        bootstrap4
                        keyField="start"
                        data={renewablePrices}
                        columns={columns}
                        bordered={false}
                        classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                        rowClasses="btn-reveal-trigger border-top border-200"
                        headerClasses="bg-200 text-900 border-y border-200"
                        {...paginationTableProps}
                      />
                    </div>
                    <Row noGutters className="px-1 py-3">
                      <Col className="pl-3 fs--1">
                        <RenewablePriceTotal {...paginationProps} lastIndex={lastIndex} />
                      </Col>
                      <Col xs="auto" className="pr-3">
                        <Button
                          color={paginationProps.page === 1 ? 'light' : 'primary'}
                          size="sm"
                          onClick={handlePrevPage(paginationProps)}
                          disabled={paginationProps.page === 1}
                          className="px-4"
                        >
                          Previous
                        </Button>
                        <Button
                          color={lastIndex >= paginationProps.totalSize ? 'light' : 'primary'}
                          size="sm"
                          onClick={handleNextPage(paginationProps)}
                          disabled={lastIndex >= paginationProps.totalSize}
                          className="px-4 ml-2"
                        >
                          Next
                        </Button>
                      </Col>
                    </Row>
                  </>
                );
              }}
            </PaginationProvider>
          )}
        </CardBody>
      </Card>
      <Modal isOpen={registerModal} >
        <ModalHeader>再エネ賦課金単価新規登録</ModalHeader>
        <ModalBody>
          <Col lg className="col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
            <FormGroup>
              <Label for="start">適用開始日</Label>
              <Input type="text" id="start" value={renewablePrice.start} onChange={({ target }) => setRenewablePrice({ ...renewablePrice, start: target.value })} />
            </FormGroup>
            <FormGroup>
              <Label for="price">単価</Label>
              <Input type="text" id="price" value={renewablePrice.price} onChange={({ target }) => setRenewablePrice({ ...renewablePrice, price: target.value })} />
            </FormGroup>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setRegisterModal(false)}>キャンセル</Button>
          <Button color="primary" onClick={() => register()}>新規登録</Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={deleteConfirmModal} >
        <ModalHeader>削除確認</ModalHeader>
        <ModalBody>
          本当に削除しますか？
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setDeleteConfirmModal(false)}>
            キャンセル
          </Button>
          <Button color="danger" onClick={() => deleteRenewablePrice()}>削除</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default RenewablePrices;
